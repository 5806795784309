body {
  background-color: #085610;
  color: #c18706;
  font-family: poppins, sans-serif; }

h1, h2, h3 {
  font-family: Playfair Display, serif;
  font-weight: 500; }

.header {
  background: rgba(8, 86, 16, 0.5) !important; }

.nav-link {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.navbar .navbar-brand,
.navbar .navbar-text {
  color: #c18706; }

/*.navbar .navbar-toggler {*/
/* color: $text-color;
    border-color: $text-color*/
/*}*/
.navbar .navbar-nav .nav-link {
  color: #c18706; }

footer .navbar .container-fluid {
  justify-content: center !important; }

.carousel-container {
  margin-left: 2em;
  margin-right: 1em; }

.hovered-text:hover {
  color: white; }
